.nav {
    position: sticky;
    top: 0;
    height: 10vh;
    width: 100vw;
    background-color: rgb(31 41 55);
    color: rgb(255, 255, 255);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo{
    /* padding: 3vh 0; */
    display: flex;
    align-items: center;
    margin-left: 3vh;
    overflow: hidden;
    height: 10vh;
}

.logo img {
    height: 50%;
    object-fit: cover;
    object-position: left;
    aspect-ratio: 1/1;
}

.dropdown{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 9vh;
    right: 0px;
    z-index: 1;
    background-color: rgb(31 41 55);
    color: rgb(255, 255, 255);
    backdrop-filter: blur(5vw);
    border-radius: 5px;
    width: 100vw;
    overflow: hidden;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4); */
}
.open{
    animation: dropdown_animation 0.3s forwards;
}
.close{
    animation: dropdown_animation_close 0.3s forwards;
}
.dropdown a{
    display: flex;
    box-sizing: border-box;
    list-style: none;
    color: rgb(255, 255, 255);
    margin: 2px;
    height: 8vh;
    padding: 1.75vh;
}
.span:hover{
    color: #f38d1d;
}
.span {
    margin: auto;
    font-size: 3vh;
}
.right{
    padding-right: 2vw;
    display: flex;
    flex-direction: row;
    gap: 2vw;
    justify-content: space-between;
}
.menu{
    height: 10vh;
    width: 10vh;
}
.backdrop {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}
.links{
    display: none;
}

@keyframes dropdown_animation{
    0%{
        height: 0px;
    }
    100%{
        height: 28vh;
    }
}

@keyframes dropdown_animation_close{
    0%{
        height: 28vh;
    }
    100%{
        height: 0px;
    }
}

@media screen  and (min-width: 650px){
    .menu {
        display: none;
    }
    .links{
        display: block;
    }
    .span {
        font-size: 3vh;
        margin: 3vh;
    }
}