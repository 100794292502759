main {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -3;
}

.heading {
  background-clip: text;
  /* font-family: "Montserrat"; */
}

#landing {
  margin-top: 13vh;
}

.anime {
  height: 80svh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.lottie {
  width: min(70vw, 50vh);
}

.events {
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  gap: 1vw;
  color: white;
  border-radius: 10px;
  padding: 1rem;
}

.arro {
  transition: all 300ms;
}

.events:hover .arro {
  scale: 1.2;
  margin-left: 1vw;
}

.bottom {
  z-index: -1;
  transform: translateZ(-1px) scale(2);
}